import React from 'react';
import PropTypes from 'prop-types';
// import 'react-lazy-load-image-component/src/effects/opacity.css';
// import 'leaflet/dist/leaflet.css';
import 'assets/css/index.sass';
// import 'swiper/swiper-bundle.css';
// import 'swiper/css/swiper.min.css';
// import 'aos/dist/aos.css';

export default function TopLayout(props) {
  const {children} = props;
  return <>{children}</>;
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
